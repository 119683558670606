import React from "react";
import SharedHeader from "../../shared/header";
import header from "../../../assets/img/components/header/Home.webp";
import ministry from "../../../assets/img/components/brand/stmdLogo.svg";
import byte from "../../../assets/img/components/network/byte.webp";

export default function Header() {
  return (
    <section id="header">
      <SharedHeader
        image={header}
        classNameImage="header-image"
        imageAltText=""
        align="right"
        titlesize="display-1"
        titlecolor="blue"
        title="Hier"
        text="entwickeln Talente zusammen mit Behörden digitale Lösungen für ein zukunftsfähiges Bayern."
        textsize="body-3"
        buttontext="Jetzt mitmachen"
        buttonTextBackgroundColor="black"
        buttonTextColor="white"
        buttonIconColor="yellow"
        buttonGridTemplateAreas={`'vred . .' '. icon text'`}
        link="/program"
        ministryLogo={ministry}
        projectLogo={byte}
      />
    </section>
  );
}
